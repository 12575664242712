<template>
  <FormWrapper
    :form="form"
    :hideForm="hideForm"
    :handleOpened="() => handleProps({ key: 'opened', value: !form.opened })"
    attachModule="ectoscopia"
  >
    <b-row>
      <b-col>
        <div class="form-group mb-0 break-spaces">
          <v-text-area
            id="ectoscopia"
            :value="form.fields.ectoscopia"
            @blur="onBlur(form)"            
            @inputTextArea="el => updateForm('ectoscopia', el)"
            :readonly="!this.validCanEdit()"
            placeholder="Descrever"
            rows="1"
          />
        </div>
      </b-col>
    </b-row>
  </FormWrapper>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import FormWrapper from '@/components/Attendance/Forms/FormWrapper'
import TextArea from '@/components/General/TextArea'

export default {
  components: {
    FormWrapper,
    'v-text-area': TextArea,
  },
  computed: {
    ...mapState({
      canEdit: state => state.attendance.canEdit,
      canDefaultForms: state => state.attendance.canDefaultForms,
      form: state => state.attendance.form.ectoscopia,
      selectedForm: state => state.attendance.form.selected,
    })
  },
  methods: {
    ...mapActions('attendance', ['editDefaultForms']),
    ...mapActions('attendance/form', ['updateMedicalRecord', 'destroyMedicalRecord', 'defaultUpdateForm']),
    ...mapActions('attendance/form/ectoscopia', ['handleFields', 'handleProps']),
    updateForm(key, value) {
      this.handleFields({ key, value })
      this.updateMedicalRecord(this.form)
    },
    onBlur(value) {
      this.updateMedicalRecord(value)
      if(this.canDefaultForms) {
        this.defaultUpdateForm(value)
        this.$toast.success('Campo ectoscopia salvo com sucesso')
      }
    },
    hideForm(){
      this.handleProps({ key: 'showing', value: false })
      this.destroyMedicalRecord(this.form)
      this.form.fields.ectoscopia = null
    },
    validCanEdit() {
      if(this.canDefaultForms === true || this.canEdit === true){
        return true
      } else {
        return false
      }
    }
  },
  watch: {
    selectedForm() {
      const form = this.selectedForm.forms.find(f => f.id === 'ectoscopia');

      if (form && form.default_text && this.canEdit) {
        setTimeout(() => {
          this.updateForm('ectoscopia', form.default_text)
					}, "3000")

      }
    }
  }
}
</script>
